import { MetricKey } from '../common/metrics';
import { type OAuthParams } from '../common/types';

export interface ApiOrganization extends ApiOrganizationSummary {
  groups: ApiGroup[];
  invited_members: ApiTeamsInvitation[];
  members: ApiOrganizationMembership[];
  terms: ApiTermsStatus;
  used_seat_counts: ApiOrganizationUsedSeatCounts;
}

export interface ApiOrganizationSummary {
  name: string;
  organization_uid: string;
  /**
   * ISO 8601 timestamp. E.g. "2019-03-31T06:50:31+00:00"
   */
  created_at: string;
  membership: ApiOrganizationMembership;
}

export enum CoachRole {
  Admin = 'admin',
  /* *
   * Coach
   */
  Coach = 'member',
}

export interface ApiOrganizationMembership {
  email: string;
  membership_uid: string;
  role: CoachRole;
}

export interface ApiTeamsInvitation {
  email: string;
  recipient_name: string;
  /**
   * ISO timestamp. E.g. "2019-03-31T06:50:31+00:00"
   */
  expires_at: string;
  /**
   * ISO timestamp. E.g. "2019-03-31T06:50:31+00:00"
   */
  sent_at: string;
  invitation_uid: string;
  is_expired: boolean;
}

export type ApiInvitationType = 'signup' | 'samsup' | 'orgsup' | 'study';

export interface ApiTermsStatus {
  latest_version: string;
  accepted_version: string | null;
}

export interface ApiGroupMembership {
  email?: string;
  name: string;
  participant_uid: string;
  /**
   * ISO8601 format: 2019-07-04T12:13:03+00:00
   */
  joined_at: string;
  latest_sync: string | null;
  label_uids: string[];
  /**
   * Not available for anonymous members.
   */
  info?: UserSettings;
}

type ApiGroupRoleType = 'viewer' | 'manager';

export interface ApiGroupRole {
  uid: string;
  created_at: string;
  membership_uid: string;
  role: ApiGroupRoleType;
}

export enum ApiGroupDataAccessLevel {
  AllTime = 'all_time',
  ParticipationTime = 'participation_time',
}
export interface ApiGroupDataAccess {
  level: ApiGroupDataAccessLevel;
}
interface ApiGroupRedirects {
  join: {
    enabled: boolean;
    url?: string;
  };
}
export interface ApiGroupConfig {
  data_access: ApiGroupDataAccess;
  redirects?: ApiGroupRedirects;
}

export interface ApiGroup {
  /**
   * ISO8601 format: 2019-07-04T12:13:03+00:00
   */
  created_at: string;
  description: string;
  name: string;
  uid: string;
  member_count: number;
  is_anonymous: boolean;
  invite_link: ApiGroupInviteLink;
  auto_guidance_enabled: boolean;
  admin_alerts_enabled: boolean;
  is_private: boolean;
  roles: ApiGroupRole[];
  config: ApiGroupConfig;
}

export interface ApiOrganizationSettings {
  send_new_org_member_email: boolean;
  send_new_study_participant_email: boolean;
  send_revoked_study_participation_email: boolean;
}

export type ModifyOrganizationSettings = Partial<ApiOrganizationSettings>;

export interface ApiOrganizationUsedSeatCounts {
  participant: number;
}

export interface ApiOrganizationSubscription {
  subscription_uid: string;
  previous_subscription_uid: string | null;
  plan: ApiOrganizationSubscriptionPlan;
  status: ApiOrganizationSubscriptionStatus;
  valid_from: string; // ISO format: "2020-12-08T08:24:59.952+00:00"
  valid_to: string | null; // ISO format: "2020-12-08T08:24:59.952+00:00"
  options: ApiOrganizationSubscriptionOption[];
}

export interface ApiOrganizationSubscriptionPlan {
  plan_uid: string;
  name: string;
  version: number;
}

export interface ApiOrganizationSubscriptionOption {
  added_at: string; // ISO format: "2020-12-08T08:24:59.952+00:00"
  removed_at: string | null; // ISO format: "2020-12-08T08:24:59.952+00:00"
  data: ApiOrganizationSubscriptionOptionData;
}

interface ApiOrganizationSubscriptionOptionData {
  version: 1;
  participant_seat_count?: number;
  blackout_mode?: boolean;
}

type ApiOrganizationSubscriptionStatus = 'trial' | 'active' | 'waived';

export interface GetOrganizationSubscriptionsResponse {
  subscriptions: ApiOrganizationSubscription[];
}

interface ApiGroupInviteLinkEnabled {
  enabled: true;
  url: string;
}

interface ApiGroupInviteLinkDisabled {
  enabled: false;
}

export type ApiGroupInviteLink =
  | ApiGroupInviteLinkDisabled
  | ApiGroupInviteLinkEnabled;

export interface ApiGroupMembers {
  members: ApiGroupMembership[];
}

interface ApiOrganizationMemberSearch {
  members: ApiOrgMemberSearchResult[];
}

export interface ApiOrgMemberSearchResult {
  email: string | null;
  name: string;
  participant_uid: string;
  group_uid: string;
  /**
   * ISO8601 format: 2019-07-04T12:13:03+00:00
   */
  joined_at: string;
}

export interface ApiGroupInvitations {
  invited_members: ApiTeamsInvitation[];
}

export interface ApiGroupStats {
  /** Array of ISO local datetimes, e.g. ["2020-04-17T14:46:05", "2020-04-17T20:10:31"] */
  participants_join_timestamps: string[];
  /** Array of ISO local datetimes, e.g. ["2020-04-17T14:46:05", "2020-04-17T20:10:31"] */
  participants_leave_timestamps: string[];
}

// These are from https://github.com/jouzen/cloud-user-data-aggregator/blob/91d636bb7b1f104429d3ef7314c6483ab1167d80/lambda/process-jzlog-aggregator/src/aggregates/types.ts
/**
 * undefined if no metric ratings available, just two limits for scores, four limits for the other 11 metrics
 * scores:  <pay attention range> [first number] <good range> [second number] <excellent range>
 * other metrics:  <pay attention range> [first number] <good range> [second number] <excellent range> [third number] <good range> [fourth number] <pay attention range>
 */
export type ApiMetricRatingLimits =
  | [number, number]
  | [number, number, number, number];

export interface ApiMetricAggregates {
  baseline_day_count: number;
  baseline?: number;
  percentiles?: {
    0: number;
    5: number;
    95: number;
    100: number;
  };
  metric_rating_limits?: ApiMetricRatingLimits;
}

export interface ApiGroupAggregates {
  [memberID: string]: {
    /**
     * ISO date. May be different than requested date.
     */
    date: string;
    aggregates: {
      [metricID: string]: ApiMetricAggregates;
    };
  };
}

// These are from https://github.com/jouzen/app-server-models/blob/master/dist/web/timeseries.d.ts
export type ApiHeartRateQuality = 'good' | 'average' | 'bad';
export type ApiHeartRateSource =
  | 'awake'
  | 'workout'
  | 'rest'
  | 'sleep'
  | 'moment'
  | 'live';

export interface ApiHeartRate {
  bpm: number;
  quality?: ApiHeartRateQuality;
  restorative?: boolean;
  source: ApiHeartRateSource;
  timestamp: string;
}

export type GetUserHeartRateResponse =
  | GetUserHeartRateResponseAvailable
  | GetUserHeartRateResponseNotAvailable;

interface GetUserHeartRateResponseAvailable {
  status: 'available';
  heart_rate: ApiHeartRate[];
  timezone: number;
}

interface GetUserHeartRateResponseNotAvailable {
  status: 'not available';
}

interface Sample {
  interval: number;
  items: Array<number | null>;
  timestamp: string;
}

export interface ActivityContributors {
  meet_daily_targets?: number;
  move_every_hour?: number;
  recovery_time?: number;
  stay_active?: number;
  training_frequency?: number;
  training_volume?: number;
}
export interface DailyActivity {
  active_calories: number;
  average_met_minutes: number;
  class_5_min?: string;
  contributors: ActivityContributors;
  day: string;
  equivalent_walking_distance: number;
  high_activity_met_minutes: number;
  high_activity_time: number;
  inactivity_alerts: number;
  low_activity_met_minutes: number;
  low_activity_time: number;
  medium_activity_met_minutes: number;
  medium_activity_time: number;
  met: Sample;
  meters_to_target: number;
  non_wear_time: number;
  resting_time: number;
  ring_met?: Sample;
  score?: number;
  sedentary_met_minutes: number;
  sedentary_time: number;
  steps: number;
  target_calories: number;
  target_meters: number;
  total_calories: number;
  timezone?: number;
}

export interface ReadinessContributors {
  activity_balance?: number;
  body_temperature?: number;
  hrv_balance?: number;
  previous_day_activity?: number;
  previous_night?: number;
  recovery_index?: number;
  resting_heart_rate?: number;
  sleep_balance?: number;
}
export interface DailyReadiness {
  contributors: ReadinessContributors;
  day: string;
  score?: number;
  temperature_deviation?: number;
  temperature_trend_deviation?: number;
}
export type CycleDayType = 'not_applicable' | 'normal' | 'cycle_fixed';
export interface Readiness {
  contributors: ReadinessContributors;
  cycle_day?: CycleDayType;
  score?: number;
  temperature_deviation?: number;
  temperature_trend_deviation?: number;
}

export interface SleepContributors {
  deep_sleep?: number;
  efficiency?: number;
  latency?: number;
  rem_sleep?: number;
  restfulness?: number;
  timing?: number;
  total_sleep?: number;
}
export interface DailySleep {
  contributors: SleepContributors;
  day: string;
  score?: number;
}

export type SegmentState = 'active' | 'dismissed' | 'rewarded';
export type SleepType =
  | 'deleted'
  | 'sleep'
  | 'long_sleep'
  | 'late_nap'
  | 'rest';
export interface Sleep {
  average_breath?: number;
  average_heart_rate?: number;
  average_hrv?: number;
  awake_time?: number;
  bedtime_end: string;
  bedtime_start: string;
  contributors?: SleepContributors;
  day: string;
  deep_sleep_duration?: number;
  efficiency?: number;
  heart_rate?: Sample;
  hrv?: Sample;
  latency?: number;
  light_sleep_duration?: number;
  lowest_heart_rate?: number;
  movement_30_sec?: string;
  period: number;
  readiness?: Readiness;
  readiness_score_delta?: number;
  rem_sleep_duration?: number;
  restless_periods?: number;
  score?: number;
  segment_state?: SegmentState;
  sleep_midpoint?: number;
  sleep_phase_5_min?: string;
  sleep_score_delta?: number;
  time_in_bed: number;
  total_sleep_duration?: number;
  type?: SleepType;
  timezone?: number;
  // These are alternative representations for ASSA data, computed by the API
  bedtime_start_delta: number;
  bedtime_end_delta: number;
  midpoint_at_delta?: number;
}

export interface DailySpO2Average {
  day: string;
  spo2_percentage: number;
}

export enum DataFormat {
  JSON = 'json',
  Other = 'other',
  CSV = 'csv',
}

const allDataFormats: Set<DataFormat> = new Set([
  DataFormat.JSON,
  DataFormat.CSV,
  DataFormat.Other,
]);

export function isValidDataFormat(s: string): s is DataFormat {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return allDataFormats.has(s as DataFormat);
}

export function isS3DataFileDescription(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  file: any,
): file is S3DataFileDescription {
  return file.key !== undefined;
}

export const DataFileLabelByKey: { [key: string]: string } = {
  'ibi.csv': 'Interbeat Interval (IBI)',
  'temperature.csv': 'Skin Temperature',
};

export type DataFileDescription =
  | PlainDataFileDescription
  | SummaryDataFileDescription
  | GroupSummaryDataFileDescription
  | S3DataFileDescription;

export interface SummaryDataFileDescription {
  type: 'summary';
  name: string;
  title: string;
}

export interface PlainDataFileDescription {
  type: 'data';
  name: string;
  title: string;
}

export interface GroupSummaryDataFileDescription {
  type: 'group-summary';
  name: string;
  title: string;
}

export interface S3DataFileDescription {
  type: 's3';
  key: string;
  title: string;
}

export type GroupDataFiles = GetGroupDataFilesResponse;

export type ApiUnits = 'metric' | 'imperial';
export type ApiGender = 'male' | 'female' | 'other';

export interface UserSettings {
  units?: ApiUnits | null;
  gender?: ApiGender | null;
  weight?: number | null;
  height?: number | null;
  age?: number | null;
}

// TODO: this is not exhaustive
export interface ApiPasswordPolicy {
  length?: number;
}

export interface ApiSetPasswordTokenData {
  email: string;
  token_expired: boolean;
  // TODO: remove optionality once server changes are complete
  token_used?: boolean;
  password_policy: ApiPasswordPolicy;
  tos_version: string | null;
  pp_version: string | null;
}

export type ApiSupportAccessRightState =
  | 'REQUESTED'
  | 'REVOKED'
  | 'GRANTED'
  | 'REJECTED';

export type ApiChatbotAccessRightState = 'REQUESTED' | 'REVOKED' | 'GRANTED';

export interface ApiSupportAccessRightRequest {
  requester_email: string;
  email: string;
  token_expired: boolean;
  state: ApiSupportAccessRightState;
  consent_text: string | null;
}

export interface ApiSupportAccessRight {
  uid: string;
  requester_email: string;
  target_user_uid: string;
  state: ApiSupportAccessRightState;
  expires_at: string; // ISO timestamp
}

export interface ApiEmailVerification {
  verified: boolean;
  email: string;
  token_used: boolean;
}

export interface ApiChatbotAccessRightRequest {
  token_expired: boolean;
  state: ApiChatbotAccessRightState;
}

//
// Request payloads and responses
//

// Terms of service
export interface GetTOSResponse {
  terms_of_service_version: string | null; // "1.0.0"
  terms_of_service_url: string; // "https://cloud.ouraring.com/terms-of-use"
  privacy_policy_version: string | null; // "1.0.0"
  privacy_policy_url: string; // "https://cloud.ouraring.com/privacy-policy"
  accepted: boolean;
}

export interface PostTOSPayload {
  terms_of_service_version: string;
  privacy_policy_version: string;
  accept_health_data_processing: true;
}

// User

export enum SubscriptionState {
  NO_SUBSCRIPTION = 'NO_SUBSCRIPTION',
  EXPIRED = 'EXPIRED',
  LIFETIME = 'LIFETIME',
  MEMBER = 'MEMBER',
  TRIAL = 'TRIAL',
  PENDING = 'PENDING',
}
export interface ApiSubscription {
  is_active: boolean;
  state: SubscriptionState;
  start_date?: string;
  end_date?: string;
}

export interface ApiHipaa {
  enabled: boolean;
}

export interface GetUserInfoResponse {
  user_uid: string;
  email: string;
  analytics_uid: string;
  user_settings: UserSettings;
  accepted_tos: boolean;
  latest_sync: string;
  // FIXME: This should always exist but for some unknown reason it seems to be missing
  // after deploying this change. Keep it optional for a bit and remove optionality at
  // some point.
  features?: ApiUserFeatures;
  subscription: ApiSubscription;
  /** Missing if we don't know the status. */
  hipaa?: ApiHipaa;
}

export interface ApiUserFeatures {
  blackout?: {
    enabled: boolean;
    features: string[];
    reason: {
      source: string; // Oura Teams group name
    };
  };
}

export type ApiOuraProduct = 'teams';

export interface ApiParticipantDataPermissions {
  share_notes: boolean;
}

export interface ApiUserParticipation {
  participant_uid: string;
  study_uid: string;
  study_name: string;
  organization_name: string;
  joined_at: string;
  product: ApiOuraProduct;
  data_permissions?: ApiParticipantDataPermissions;
}

export interface GetApiUserParticipationsResponse {
  teams: ApiUserParticipation[];
}

export interface ApiUserNote {
  day: string;
  timestamp: string;
  tags: string[];
  text: string | null;
}

export type GetApiUserNotesResponse =
  | { status: UserNoteStatus.Available; notes: ApiUserNote[] }
  | { status: UserNoteStatus.NotAvailable };

export interface ApiUserAuthorizedApplication {
  uid: string;
  app_name: string;
  site_name: string;
  url: string;
  privacy_policy_url: string;
  terms_of_service_url: string;
}

export interface GetApiUserApplicationsResponse {
  authorized_applications: ApiUserAuthorizedApplication[];
}

export interface ApiUserIntegration {
  id: string;
  name: string;
  web_url: string; // this is optional from PIE end but should be required for responses to ootw
  mobile_authorization_uri: string;
  app_url?: string;
  oauth_parameters: OAuthParams;
  connected: boolean;
  redirect_uri: string;
  icon_url: string;
}

export interface ApiConnectIntegrationResponse {
  app_id: string;
  enterprise_id: string | null;
  oura_partner: string;
  oura_user_id: string;
  slack_user_id: string;
  slack_workspace_id: string;
}

export interface GetApiUserIntegrationsResponse {
  partners: ApiUserIntegration[];
}

export interface GetOAuthInstallResponse {
  message: string;
  nonce: string;
}

export interface PostConnectIntegrationPayload {
  id: string;
  state?: string | null;
  code: string;
}

export interface PostConnectIntegrationResponse {
  appId: string;
  enterpriseId: string | null;
  ouraPartner: string;
  ouraUserId: string;
  slackUserId: string;
  slackWorkspaceId: string;
}

export interface PostDisconnectIntegrationPayload {
  integrationId: string;
}

export type ApiTimeAggregation = 'daily' | 'weekly' | 'monthly';
export type ApiChartType = 'trend';
export interface ApiChartConfiguration {
  type: ApiChartType;
  left_y: string; // metric key
  right_y?: string; // metric key
}
export interface ApiNamedTrendsSetting {
  name: string;
  time_aggregation: ApiTimeAggregation;
  charts: ApiChartConfiguration[];
}
// This should be considered immutable
interface ApiTrendsSettingsVersion1 {
  settings_type: 'trends';
  version: 1;
  configurations: ApiNamedTrendsSetting[];
}
export type TrendsSettingsResponse = ApiTrendsSettingsVersion1;
export type TrendsSettingsPayload = ApiTrendsSettingsVersion1;

export interface PostSetPasswordPayload
  extends Pick<ApiSetPasswordTokenData, 'tos_version' | 'pp_version'> {
  token: string;
  password: string;
  password_confirmation: string;
  accept_health_data_processing: boolean;
  accept_marketing: boolean;
}

export interface PostSetPasswordResponse {
  email: string;
}

export type PostResendSetPasswordEmailResponse = PostSetPasswordResponse;

export interface PostResendSetPasswordEmailPayload {
  token: string;
  locale: string;
}

export interface PostResetPasswordPayload {
  token: string;
  password: string;
  password_confirmation: string;
}

export interface PutSupportAccessRightRequestPayload {
  token: string;
  accept_support_access: boolean;
  expire_access_timeout_seconds?: number;
}

export interface PutSupportAccessRightRequestResponse {
  requester_email: string;
  email: string;
  state: ApiSupportAccessRightState;
}

export interface GetSupportAccessRightsResponse {
  support_access_rights: ApiSupportAccessRight[];
}

export interface PostEmailVerificationPayload {
  token: string;
}

export interface PutChatbotAccessRightRequestPayload {
  token: string;
  accept_chatbot_session: boolean;
}

export interface PutChatbotAccessRightRequestResponse {
  state: ApiChatbotAccessRightState;
  expires_at: string;
}

// Group
export type GetGroupStatsResponse = ApiGroupStats;
export type GetGroupMembersResponse = ApiGroupMembers;
export type GetGroupMembersSearchResponse = ApiGroupMembers;
export type GetGroupInvitationsResponse = ApiGroupInvitations;
export type CreateGroupResponse = ApiGroup;
export type GetOrgMemberSearchResponse = ApiOrganizationMemberSearch;
export interface CreateGroupPayload {
  name: string;
  is_anonymous?: boolean;
  is_private?: boolean;
  data_access?: string;
}
export interface ModifyGroupPayload {
  name?: string;
  invite_link?: boolean;
  redirect_url_join?: {
    enabled: boolean;
    url?: string;
  };
}

export type ModifyGroupResponse = ApiGroup;
export type DeleteGroupResponse = {};
export type RemoveMemberResponse = {};

export type AddGroupRoleResponse = ApiGroupRole;
export type RemoveGroupRoleResponse = {};
export interface AddGroupRolePayload {
  membership_uid: string;
  role: ApiGroupRoleType;
}

export interface ApiTeamsAgreement {
  uid: string;
  type: number;
  version: string;
}

export interface GetTeamsAgreementsResponse {
  agreements: ApiTeamsAgreement[];
}

// Organization
export interface ListOrganizationsResponse {
  organizations: ApiOrganizationSummary[];
}
export type GetOrganizationResponse = ApiOrganization;
export interface ModifyOrganizationPayload {
  name: string;
}
export interface ModifyOrganizationResponse {
  organization: ApiOrganization;
  name: string;
}

export type DeleteOrganizationResponse = {};

export interface ModifyMemberPayload {
  participant_name: string;
}

export type ModifyMemberResponse = ApiGroupMembership;

export interface AcceptTermsPayload {
  version: string;
}
export type AcceptTermsResponse = ApiOrganization;

/**
 * Response is {} if the current user is no longer a member of the organization
 */
export type RemoveCoachResponse = ApiOrganization | {};
export interface ChangeRolePayload {
  role: CoachRole;
}
export type ChangeRoleResponse = ApiOrganization;

// View settings
export interface ApiOrganizationViewSettingsResponse {
  /**
   * ISO8601 format: 2019-07-04T12:13:03+00:00
   */
  created_at: string;
  /**
   * ISO8601 format: 2019-07-04T12:13:03+00:00
   */
  updated_at: string;
  json: ApiOrganizationViewSettingsJSON;
}

export type ApiGroupMarkerType = 'average' | 'baseline' | 'movingAverage';

export interface ApiOrganizationViewSettingsJSON {
  version: 1;
  view: {
    date_range_days: number;
    markers: ApiGroupMarkerType[];
    selected_metric_set_uid: string | null;
    metric_set_uids_ordered: string[];
  };
}

export interface ApiMetricSetResponse {
  uid: string;
  // ISO8601 format: 2019-07-04T12:13:03+00:00
  created_at: string;
  // ISO8601 format: 2019-07-04T12:13:03+00:00
  updated_at: string;
  json: {
    version: 2;
    name: string;
    metrics: string[];
  };
}

export interface ApiMetricSetsResponse {
  metric_sets: ApiMetricSetResponse[];
}

export interface CreateMetricSetPayload {
  version: 2;
  name: string;
  metrics: MetricKey[];
}

export type ModifyMetricSetPayload = CreateMetricSetPayload;

// Invitations
export interface InvitationPayload {
  name?: string;
  email: string;
  externalId?: string;
}
export interface InvitationBulkPayload {
  recipients: InvitationPayload[];
}
export type InviteCoachResponse = ApiOrganization;
export type InviteMemberResponse = ApiGroupInvitations;
export enum InvitationStatus {
  Open = 'open',
  Accepted = 'accepted',
}
export interface SuccessfulResendInvitationResponse {
  status: InvitationStatus.Open;
  invitation: ApiTeamsInvitation;
  invitation_type: ApiInvitationType;
}
export interface AlreadyAcceptedInvitationResponse {
  status: InvitationStatus.Accepted;
  invitation_uid: string;
  invitation_type: ApiInvitationType;
  /**
   * For group invitations.
   */
  study?: ApiGroupInvitations;
  /**
   * For organization invitations.
   */
  organization?: ApiOrganization;
}
export type ResendInvitationResponse =
  | AlreadyAcceptedInvitationResponse
  | SuccessfulResendInvitationResponse;
export interface RemoveInvitationResponse {
  invitation: { created_at: string; uid: string };
}

export interface InvitationResponse {
  accepted_at?: string | null;
  is_expired: boolean;
  org_name: string;
}

// Data
export interface GetDailyDataResponse {
  sleeps: Sleep[];
  daily_activities: DailyActivity[];
  daily_readinesses: DailyReadiness[];
  daily_sleeps: DailySleep[];
  daily_spo2_averages: DailySpO2Average[];
}

export interface GetGroupMembersDailyMetricsResponse {
  [memberID: string]: Array<
    { date: string } & { [metricID: string]: number | undefined }
  >;
}

export type GetGroupAggregatesResponse = ApiGroupAggregates;

// Data files

export type GetGroupDataFilesResponse = {
  [type in DataFormat]: DataFileDescription[];
};

// REMOTE APP MANAGEMENT: BLACKOUT MODE
export type ApiBlackoutPeriodFeatureID =
  | 'activity_details'
  | 'bedtime_guidance'
  | 'home_activity'
  | 'home_readiness'
  | 'home_sleep'
  | 'home_shortcuts'
  | 'home_timeline'
  | 'body_clock'
  | 'introducing_oura'
  | 'readiness_details'
  | 'readiness_message'
  | 'sleep_details'
  | 'trends'
  | 'firmware_updates'
  | 'naps_confirmation'
  | 'naps_details'
  | 'restful_periods'
  | 'rest_mode'
  | 'daytime_hr'
  | 'explore_tab'
  | 'tell_a_friend'
  | 'social_sharing'
  | 'period_prediction'
  | 'circles'
  | 'weekly_report'
  | 'reports'
  | 'experiments'
  | 'automatic_activity_detection'
  | 'workout_hr'
  | 'calories'
  | 'manual_workouts'
  | 'resilience'
  | 'oura_labs'
  | 'cardiovascular_age'
  | 'fertile_window'
  | 'symptom_radar'
  | 'meals_and_glucose'
  | 'advisor_chat'
  | 'daytime_stress';

export interface ApiBlackoutPeriod {
  id: string;
  valid_from: string; // ISO UTC timestamp
  valid_to: string; // ISO UTC timestamp
  features: ApiBlackoutPeriodFeatureID[];
  comment: string | null;
}

export interface ApiGetBlackoutPeriodsResponse {
  periods: ApiBlackoutPeriod[]; // Sorted by valid_from, ascending
}

export interface ApiCreateBlackoutPeriodPayload {
  valid_from: string; // ISO UTC timestamp
  valid_to: string; // ISO UTC timestamp
  features: ApiBlackoutPeriodFeatureID[];
  comment?: string;
}

export type ApiCreateBlackoutPeriodResponse = ApiBlackoutPeriod;

// returns the blackout period object with valid_to set as now if you delete
// active blackout period, otherwise, returns null if you delete upcoming blackout period
export type ApiDeleteBlackoutPeriodResponse = ApiBlackoutPeriod | null;

export interface ApiGroupLabel {
  uid: string;
  name: string;
  color: string;
  description: string;
  created_at: string; // ISO UTC timestamp
}

export interface ApiGetGroupLabelsResponse {
  labels: ApiGroupLabel[];
}

export type ApiCreateGroupLabelResponse = ApiGroupLabel;

export type ApiCreateGroupLabelPayload = Pick<
  ApiGroupLabel,
  'color' | 'name' | 'description'
>;

export type ApiUpdateGroupLabelPayload = ApiCreateGroupLabelPayload;

export type ApiUpdateGroupLabelResponse = ApiGroupLabel;

export interface ApiUpdateGroupMemberLabelsPayload {
  label_uids: string[];
}

export type ApiUpdateGroupMemberLabelsResponse =
  ApiUpdateGroupMemberLabelsPayload;

export enum UserNoteStatus {
  Available = 'available',
  NotAvailable = 'not available',
  PermissionDenied = 'permission denied',
}

export type ApiGetGroupMemberNotesResponse =
  | {
      notes: ApiUserNote[];
      status: UserNoteStatus.Available;
    }
  | { status: UserNoteStatus.NotAvailable }
  | { status: UserNoteStatus.PermissionDenied };

export interface ApiGetGroupNotesResponse {
  [participantId: string]: ApiGetGroupMemberNotesResponse;
}

export interface ApiTodayScores {
  daily_sleep?: { score: number };
  daily_activity?: { score: number };
  daily_readiness?: { score: number };
  date: string;
}

export type ApiCirclesInviteStatus = 'valid' | 'invalid' | 'expired' | 'used';

export interface ApiCirclesInviteTokenInfo {
  invite: {
    status: ApiCirclesInviteStatus;
  };
  raf_url?: string;
}

export type CreateGroupLabelPayload = ApiCreateGroupLabelPayload;

export type UpdateGroupLabelPayload = ApiUpdateGroupLabelPayload;

export interface UpdateGroupMemberLabelsPayload {
  labelIDs: string[];
}

// Data Exports

export interface ApiGetOrganizationDataExportSettingsResponse {
  data_types: string[];
  groups: Array<{
    uid: string;
    name: string;
    created_at: string;
    is_private: boolean;
    is_anonymous: boolean;
    access_level: string;
  }>;
  labels: Array<{
    uid: string;
    name: string;
    created_at: string;
    group_name: string;
    group_uid: string;
    color: string;
  }>;
  participants: Array<{ uid: string; name: string }>;
}

export enum ApiExportFormat {
  CSV = 'csv',
  JSON = 'json',
}

export enum ApiExportType {
  group = 'group',
  organization = 'org',
  label = 'label',
  member = 'member',
}

export enum ApiFileConventionType {
  name = 'name',
  id = 'id',
  name_group = 'name_group',
  id_group = 'id_group',
}

export interface ApiFileInfo {
  name: string;
  url: string;
}

export interface ApiExportInfo {
  task_name: string;
  start_time: string;
  end_time: string;
  data_types: string[];
  export_config: {
    format: ApiExportFormat;
    individual_user_files: boolean;
  };
  status: string;
  files?: ApiFileInfo[];
  created_at: string;
  expires_at: string;
}

export interface ExportDataRequestPayload {
  taskName: string;
  startTime: string;
  endTime: string;
  dataTypes: string[];
  exportFormat: ApiExportFormat;
  individualFiles: boolean;
  exportType: ApiExportType;
  fileConvention: ApiFileConventionType;
  ids: string[];
}

export interface ApiGetDataExportsResponse {
  items: ApiExportInfo[];
}
