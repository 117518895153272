import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import {
  addDays,
  differenceInCalendarDays,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameYear,
  parse,
  parseISO,
  startOfDay,
  subDays,
} from 'date-fns';

/**
 * Formats the date into a local date (YYYY-MM-DD)
 */
export const toDateString = timeFormat('%Y-%m-%d');

/**
 * Parse an ISO-formatted date string.
 */
export function dateParse(s: string) {
  return parse(s, 'yyyy-MM-dd', new Date());
}

export function parseLocalDay(s: string) {
  const date = dateParse(s);
  const day = date && startOfDay(date);

  return day;
}

export function getToday() {
  return startOfDay(new Date());
}

export function equalDates(d1: Date, d2: Date) {
  return d1 && d2 && d1.getTime() === d2.getTime();
}

/**
 * This is useful for making sure the year number (or month in daily axis)
 * aligns with the edges of the axis. For example, if the date range starts from
 * Jan 10, the year/month label is shown under the 10th of January.
 */
export function clampDate(
  { start, end }: { start: Date; end: Date },
  date: Date,
): Date {
  return isBefore(date, start) ? start : isAfter(date, end) ? end : date;
}

/**
 * Converts an ISO-8601 date to locale timezone, ignoring the timezone offset
 */
export function parseWithoutTimezone(isoString: string) {
  // Datetime part is 19 chars: 2017-06-23T22:43:52
  const time = isoString.slice(0, 19);
  return parse(time, "yyyy-MM-dd'T'HH:mm:ss", new Date());
}

const yearFormat = timeFormat('%Y');
const monthFormat = timeFormat('%b');
const dayFormat = timeFormat('%e');
const dayOfWeekFormat = timeFormat('%a');

export function formatDateSpan(firstDate: Date, secondDate: Date) {
  let dateString = `${monthFormat(firstDate)} ${dayFormat(firstDate)}`;

  if (isSameDay(firstDate, secondDate)) {
    return `${dayOfWeekFormat(firstDate)}, ${dateString}`;
  }

  if (!isSameYear(firstDate, secondDate)) {
    dateString += `, ${yearFormat(firstDate)}`;
  }

  dateString += ' – ';

  if (!isSameMonth(firstDate, secondDate)) {
    dateString += `${monthFormat(secondDate)} `;
  }

  dateString += `${dayFormat(secondDate)}, ${yearFormat(secondDate)}`;

  return dateString;
}

export function minutesFormatter(mins: number) {
  const h = Math.floor(mins / 60) || 0;
  const m = Math.floor(mins - h * 60) || 0;

  return `${format('d')(h)}:${format('02')(m)}`;
}

export function secondsFormatter(secs: number) {
  const h = Math.floor(secs / (60 * 60)) || 0;
  const m = Math.floor((secs - h * 60 * 60) / 60) || 0;

  return `${format('d')(h)}:${format('02')(m)}`;
}

function minutesToMilliseconds(mins: number) {
  return mins * 60 * 1000;
}

export function shiftDateByOffset(isoString: string, offset: number) {
  const date = parseISO(isoString);
  const epoch = date.getTime();
  const dateTimezone = date.getTimezoneOffset();
  // getTimezoneOffset returns offset opposite direction of normal notation
  // e.g. helsinki +02.00 => -120

  const newEpoch =
    epoch + minutesToMilliseconds(offset) + minutesToMilliseconds(dateTimezone);

  return new Date(newEpoch);
}

export function shiftDateRange(
  start: Date,
  end: Date,
  direction: 'backwards' | 'forwards',
) {
  const dayDiff = differenceInCalendarDays(end, start);
  const moveAmount = direction === 'forwards' ? dayDiff + 1 : -(dayDiff + 1);
  const shiftedEndDate = addDays(end, moveAmount);
  const today = getToday();
  const newEndDate = isAfter(shiftedEndDate, today) ? today : shiftedEndDate;
  const startDate = subDays(newEndDate, dayDiff);

  return { start: startDate, end: newEndDate };
}
