export const CREATE_GROUP = 'create_group';
export const DELETE_GROUP = 'delete_group';
export const DELETE_ORG = 'delete_org';
export const ORG_MEMBER_INVITE = 'org_member_invite';
export const ORG_MEMBER_REMOVE_INVITE = 'org_member_remove_invite';
export const ORG_MEMBER_REMOVE = 'org_member_remove';
export const GROUP_ANALYSIS_DATE_PICKER = 'group_analysis_date_picker';
export const GROUP_MEMBER_INVITE = 'group_member_invite';
export const GROUP_MEMBER_REMOVE_INVITE = 'group_member_remove_invite';
export const GROUP_MEMBER_REMOVE = 'group_member_remove';
export const ORG_SELECTION = 'org_selection';
export const EXPORT_TRENDS = 'export_trends';
export const PRIVATE_GROUP_ROLE_ADD = 'private_group_role_add';
export const PRIVATE_GROUP_ROLE_REMOVE = 'private_group_role_remove';
export const REVOKE_APP_ACCESS = 'revoke_app_access';
export const REVOKE_GROUP_ACCESS = 'revoke_group_access';
export const REVOKE_SUPPORT_ACCESS = 'revoke_support_access';
export const ADD_BLACKOUT_PERIOD = 'add_blackout_period';
export const DELETE_BLACKOUT_PERIOD = 'delete_blackout_period';
export const SEARCH_TAGS = 'search_tags';
export const DELETE_ACCOUNT = 'delete_account';

export const all = [
  CREATE_GROUP,
  DELETE_GROUP,
  DELETE_ORG,
  ORG_MEMBER_INVITE,
  ORG_MEMBER_REMOVE_INVITE,
  ORG_MEMBER_REMOVE,
  GROUP_ANALYSIS_DATE_PICKER,
  GROUP_MEMBER_INVITE,
  GROUP_MEMBER_REMOVE_INVITE,
  GROUP_MEMBER_REMOVE,
  ORG_SELECTION,
  EXPORT_TRENDS,
  PRIVATE_GROUP_ROLE_ADD,
  PRIVATE_GROUP_ROLE_REMOVE,
  REVOKE_APP_ACCESS,
  REVOKE_GROUP_ACCESS,
  REVOKE_SUPPORT_ACCESS,
  ADD_BLACKOUT_PERIOD,
  DELETE_BLACKOUT_PERIOD,
  SEARCH_TAGS,
  DELETE_ACCOUNT,
];
