import { mean as d3mean } from 'd3-array';
import { nest } from 'd3-collection';
import {
  CountableTimeInterval,
  timeDay,
  timeHour,
  timeMonday,
  timeMonth,
  timeSunday,
} from 'd3-time';
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
} from 'date-fns';
import { Dictionary, get } from 'lodash';
import { useEffect, useRef } from 'react';

import { Sleep } from '../api/types';
import { FirstDayOfWeek, useLocale } from '../localization/LocaleContext';
import { clampDate, dateParse, shiftDateByOffset, toDateString } from './dates';
import { getChartUnitsConverter } from './measurement';
import { mean, MeasurementValue, toMeasurementValue } from './measurements';
import { MetricKey } from './metrics';
import {
  ArrayElementType,
  DailyData,
  DateStats,
  LocaleData,
  NumericStats,
  Stats,
  TimeAggregation,
  UserIntegration,
  UserNotes,
} from './types';

export function sleep(ms: number): Promise<undefined> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(undefined), ms);
  });
}

export function intersperse<T>(arr: T[], separator: (n: number) => T): T[] {
  return arr.flatMap((a, i) => (i > 0 ? [separator(i - 1), a] : [a]));
}

export function getFrequencies<T extends string | number>(
  arr: T[],
): Dictionary<number> {
  const freqs: Dictionary<number> = {};
  arr.forEach((curr) => {
    freqs[curr] = (freqs[curr] ?? 0) + 1;
  });
  return freqs;
}

export function getTimeInterval(aggregationType: TimeAggregation) {
  switch (aggregationType) {
    case 'daily':
      return timeDay;

    case 'weekly':
      return timeMonday;

    case 'monthly':
      return timeMonth;

    default:
      return assertNever(aggregationType);
  }
}

export function getTagsByFrequency(
  notes: UserNotes,
): Array<[tag: string, frequency: number]> {
  const freqs = getFrequencies(
    Object.values(notes)
      .map((dayNotes) => (dayNotes ?? []).map((note) => note.tags))
      .flat(2),
  );
  const tagFrequencies = Object.entries(freqs ?? {}).sort(
    ([_1, a], [_2, b]) => b - a,
  );
  return tagFrequencies;
}

export function extendNumberDomain(domain: [number, number], by: number) {
  return [domain[0] - by, domain[1] + by];
}

export function extendTimeDomain(
  domain: [Date, Date],
  interval: CountableTimeInterval,
  by: number,
) {
  return [interval.offset(domain[0], -by), interval.offset(domain[1], by)];
}

export function extendedBedtime(data: Sleep): [start: Date, end: Date] {
  const bedtimeStart = shiftDateByOffset(
    data.bedtime_start,
    data.timezone ?? 0,
  );
  const bedtimeEnd = shiftDateByOffset(data.bedtime_end, data.timezone ?? 0);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const interval = timeHour.every(1)!;
  const start = interval.floor(bedtimeStart);
  const end = interval.ceil(bedtimeEnd);
  return [start, end];
}

export function assertNever(x: never): never {
  throw new Error(`Unexpected value: ${x}`);
}

export function capitalize(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

// From: https://github.com/manishsaraan/email-validator/blob/master/index.js
const emailTester =
  /^[\w!#$%&'*+-/=?^{|}~](\.?[\w!#$%&'*+-/=?^`{|}~])*@[\dA-Za-z](-*\.?[\dA-Za-z])*\.[A-Za-z](-?[\dA-Za-z])+$/;
export function validateEmail(email: string) {
  if (!email) {
    return false;
  }

  if (email.length > 254) {
    return false;
  }

  const valid = emailTester.test(email);
  if (!valid) {
    return false;
  }

  // Further checking of some things regex can't handle
  const parts = email.split('@');
  if (parts[0].length > 64) {
    return false;
  }

  const domainParts = parts[1].split('.');
  if (domainParts.some((part) => part.length > 63)) {
    return false;
  }

  return true;
}

export function isDefined<T>(v: T | undefined | null): v is T {
  return v != null;
}

/**
 * Does a loose UUID check. Doesn't check hex group lengths.
 */
export function validateUUID(uid: string) {
  // eslint-disable-next-line unicorn/regex-shorthand
  return uid.toLowerCase().match(/^[\dA-Fa-f-]{36}$/);
}

export function booleanObjectToList(obj: { [d: string]: boolean }) {
  return Object.entries(obj)
    .filter(([, enabled]) => enabled)
    .map(([marker]) => marker);
}

// Based on https://usehooks.com/usePrevious/
export function usePrevious<T>(value: T) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

// Pluck stats for a single measurement from stats object
export function pluckStats(
  stats: Stats | undefined,
  key: MetricKey | string,
): DateStats | NumericStats | null {
  if (key.startsWith('sleep.')) {
    return get(stats, `longest_${key}`) ?? null;
  }
  return get(stats, key) ?? null;
}

export interface QueryValue {
  x: Date;
  y1?: MeasurementValue | undefined;
  y2?: MeasurementValue | undefined;
}

export function getDailyDataValue(data: DailyData, key: MetricKey): number {
  if (key.startsWith('sleep.')) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return get(data, `longest_${key}`) as number;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return get(data, key) as number;
}

/**
 * Legacy version of getQueryValues, used specifically for TrendViewOverview
 * @note: Pulled from ecosys-237-button-not-working-with-keyboard (last PR before TrendViewOverview was removed)
 */
export function getQueryValuesLegacy(
  locale: LocaleData,
  { y1, y2 }: { y1: MetricKey; y2?: MetricKey },
  timeAggregation: TimeAggregation,
  interval: CountableTimeInterval,
  data: DailyData[],
): Array<{
  x: Date;
  y1?: number | undefined;
  y2?: number | undefined;
}> {
  const chartConverter = getChartUnitsConverter(locale, y1);
  const y2ChartConverter = y2 ? getChartUnitsConverter(locale, y2) : undefined;

  const plucked = data.map((item) => {
    const y1Value = getDailyDataValue(item, y1);
    const y2Value = y2 ? getDailyDataValue(item, y2) : undefined;
    return {
      x: dateParse(item.date),
      y1: y1Value ? chartConverter(y1Value) : undefined,
      y2: y2Value && y2ChartConverter ? y2ChartConverter(y2Value) : undefined,
    };
  });

  if (timeAggregation === 'daily') {
    return plucked;
  }

  const nested = nest<
    ArrayElementType<typeof plucked>,
    { y1?: number; y2?: number }
  >()
    .key((d) => toDateString(interval(d.x)))
    .rollup((ds) => ({
      y1: d3mean(ds.map((d) => d.y1)),
      y2: d3mean(ds.map((d) => d.y2)),
    }))
    .entries(plucked);

  const fixed = nested.map(
    (d: { key: string; value: { y1?: number; y2?: number } | undefined }) => ({
      x: dateParse(d.key),
      y1: Number.isFinite(d.value?.y1) ? d.value?.y1 : undefined,
      y2: Number.isFinite(d.value?.y2) ? d.value?.y2 : undefined,
    }),
  );

  return fixed;
}

export function getQueryValues(
  locale: LocaleData,
  { y1, y2 }: { y1: MetricKey; y2?: MetricKey },
  timeAggregation: TimeAggregation,
  interval: CountableTimeInterval,
  data: DailyData[],
): QueryValue[] {
  const plucked = data.map((item) => {
    const y1Value = getDailyDataValue(item, y1);
    const y2Value = y2 ? getDailyDataValue(item, y2) : undefined;
    return {
      x: dateParse(item.date),
      y1: y1Value != null ? toMeasurementValue(locale, y1, y1Value) : undefined,
      y2:
        y2 && y2Value != null
          ? toMeasurementValue(locale, y2, y2Value)
          : undefined,
    };
  });

  if (timeAggregation === 'daily') {
    return plucked;
  }

  const nested = nest<
    ArrayElementType<typeof plucked>,
    { y1?: MeasurementValue; y2?: MeasurementValue }
  >()
    .key((d) => toDateString(interval(d.x)))
    .rollup((ds) => ({
      y1: mean(ds.map((d) => d.y1)),
      y2: y2 ? mean(ds.map((d) => d.y2)) : undefined,
    }))
    .entries(plucked);

  const fixed = nested.map((d) => ({
    x: dateParse(d.key),
    y1: d.value?.y1,
    y2: d.value?.y2,
  }));

  return fixed;
}

export function getStatsExtent(
  locale: LocaleData,
  stats: Stats | undefined,
  key: MetricKey,
): [number, number] | [Date, Date] | null {
  const metricStats = pluckStats(stats, key);
  if (
    typeof metricStats?.min === 'number' &&
    typeof metricStats.max === 'number'
  ) {
    const { min, max } = metricStats;
    const a = toMeasurementValue(locale, key, min);
    const b = toMeasurementValue(locale, key, max);
    if (a?.value instanceof Date && b?.value instanceof Date) {
      return [a.value, b.value];
    }
    if (typeof a?.value === 'number' && typeof b?.value === 'number') {
      return [a.value, b.value];
    }
  }
  return null;
}

export function useTimeInterval(aggregationType: TimeAggregation) {
  const { firstDayOfWeek } = useLocale();
  switch (aggregationType) {
    case 'daily':
      return timeDay;

    case 'weekly':
      return firstDayOfWeek === 0 ? timeSunday : timeMonday;

    case 'monthly':
      return timeMonth;

    default:
      return assertNever(aggregationType);
  }
}

export function getAggregationDates(
  [start, end]: Date[],
  aggregation: TimeAggregation,
  firstDayOfWeek: FirstDayOfWeek,
) {
  switch (aggregation) {
    case 'daily':
      return eachDayOfInterval({ start, end });
    case 'weekly':
      return eachWeekOfInterval(
        { start, end },
        { weekStartsOn: firstDayOfWeek },
      ).map((date) => clampDate({ start, end }, date));
    case 'monthly':
      return eachMonthOfInterval({ start, end }).map((date) =>
        clampDate({ start, end }, date),
      );
    default:
      return assertNever(aggregation);
  }
}

export function isValidUUID(uid: string) {
  return /^[\da-f]{8}-[\da-f]{4}-[0-5][\da-f]{3}-[089ab][\da-f]{3}-[\da-f]{12}$/i.test(
    uid,
  );
}

export function isValidHttpsUrl(url: string): boolean {
  try {
    const test = new URL(url);
    return test.protocol === 'https:' && url.startsWith('https://');
  } catch {
    return false;
  }
}

/**
 *
 * @param hex The full hex string, including the number sign, eg. "#c0ffee"
 * @param threshold Optional lightness threshold from 0 to 1
 */
export function hexColorIsDark(hex: string, threshold = 0.5) {
  // #rrggbb
  // 0123456
  const raw = { r: hex.slice(1, 3), g: hex.slice(3, 5), b: hex.slice(5, 7) };
  const numeric = [raw.r, raw.g, raw.b].map(
    (n) => Number.parseInt(`0x${n}`, 16) / 255,
  );

  // https://en.wikipedia.org/wiki/HSL_and_HSV#From_RGB
  const xMax = Math.max(...numeric);
  const xMin = Math.min(...numeric);
  const lightness = (xMax + xMin) / 2;

  return lightness < threshold;
}

export function computeClusters(
  distance: number,
  sorted: number[],
): number[][] {
  if (sorted.length === 0) {
    return [];
  }

  // Initialize with first value
  let cluster: number[] = [sorted[0]];

  const result: number[][] = [];
  sorted.forEach((n, i) => {
    if (i === 0) {
      return;
    }
    const last = cluster[cluster.length - 1];
    // Does the value belong to this cluster?
    if (n < last + distance) {
      cluster.push(n);
    } else {
      result.push(cluster);
      cluster = [n];
    }
  });
  if (cluster.length > 0) {
    result.push(cluster);
  }

  return result;
}

export function getCookie(name: string) {
  return document.cookie.match(new RegExp(`${name}=(.*?)(;|$)`))?.[1];
}

export function buildIntegrationsAuthUrl(
  nonce: string,
  integration: UserIntegration,
): string {
  // Temporary: Will come from back-end eventually on getInstallIntegration response
  const extraParams: { [key: string]: { [key: string]: string } } = {
    slack: {
      user_scope: 'identify,users.profile:read,users.profile:write,users:read',
    },
  };

  const authUrlParams = new URLSearchParams({
    response_type: 'code',
    redirect_uri: `${window.location.protocol}//${window.location.host}/profile/integrations/connect/${integration.id}`,
    state: nonce,
    ...extraParams[integration.id],
    ...integration.oauthParams,
  });

  return `${integration.webUrl}?${authUrlParams.toString()}`;
}

export function removeSlackNotConnectedIntegration(
  integration: UserIntegration,
) {
  return (
    (integration.id === 'slack' && integration.connected) ||
    integration.id !== 'slack'
  );
}
