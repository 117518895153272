import { Measurement, Metric, MetricKey, metrics } from './metrics';
import { LocaleData } from './types';

// Normalization

/**
 * Represents a value that has been run through `normalizeValue`
 * and is now in the default units for that particular measurement.
 */
interface NormalizedValue {
  measurement: Measurement;
  value: number;
}

/**
 * Converts raw measurement values into the normalized unit. For example all durations
 * are normalized into seconds. This allows us to only write the unit conversions
 * "from seconds" and thus avoids the need for multitude of conversion directions
 * (minutes to hours, minutes to seconds, hours to minutes, etc).
 */
function normalizeValue(metric: Metric, raw: number): NormalizedValue {
  switch (metric.data_unit) {
    case 'hour':
      return { measurement: metric.measurement, value: raw * 60 * 60 };

    case 'half_hour':
      return { measurement: metric.measurement, value: raw * 30 * 60 };

    case 'minute':
      return { measurement: metric.measurement, value: raw * 60 };

    default:
      return { measurement: metric.measurement, value: raw };
  }
}

// Data format

const unitSymbols = {
  millisecond: 'ms',
  second: 's',
  minute: 'min',
  hour: 'h',

  meter: 'm',
  kilometer: 'km',
  mile: 'miles',

  kcal: 'kcal',
  large_calorie: 'Cal',

  percentage: '%',
  score: '',

  count: 'times',
  warning: 'alerts',

  beats_per_minute: 'bpm',
  breaths_per_minute: '/min',

  celsius: '°C',
  fahrenheit: '°F',

  met: 'MET',
} as const;

// Conversions

function convertTo(unit: string, { value }: NormalizedValue) {
  switch (unit) {
    case 'mile':
      return value * 0.000621371;

    // NOTE: This will not work for absolute temperatures, only for deltas!
    case 'fahrenheit':
      return value * (9 / 5);

    case 'kilometer':
      return value / 1000;

    case 'minute':
      return value / 60;

    case 'hour':
    case 'time_of_day_delta_hours':
      return value / 3600;

    default:
      return value;
  }
}

const hasSpaceBetweenValueAndSymbol: { [unit: string]: boolean | undefined } = {
  mile: true,
  breaths_per_minute: true,
  count: true,
  warning: true,
  large_calorie: true,
};

function toTargetUnits(
  locale: LocaleData,
  metric: Metric,
  targetUnits: 'display_unit' | 'chart_unit',
  raw: number,
) {
  // Use the requested format, or fall back to display format
  const unitSpecs = metric[targetUnits] || metric.display_unit;
  const unit =
    typeof unitSpecs === 'object' ? unitSpecs[locale.units] : unitSpecs;

  return {
    value: convertTo(unit, normalizeValue(metric, raw)),
    hasSpace: hasSpaceBetweenValueAndSymbol[unit],
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    unitSymbol: (unitSymbols as Record<string, string>)[unit],
  };
}

// eslint-disable-next-line import/prefer-default-export
export function getChartUnitsConverter(locale: LocaleData, key: MetricKey) {
  return function convertToChartUnits(raw: number): number {
    const metric: Metric = metrics[key];
    return toTargetUnits(locale, metric, 'chart_unit', raw).value;
  };
}
