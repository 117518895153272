// Core-js needs to be imported first
import 'core-js/stable';

import { lazy, Suspense } from 'react';
import { render } from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ReactModal from 'react-modal';
import { Route, Routes } from 'react-router-dom';

import AppProvider from './AppProvider';
import { logException } from './common/sentry';
import ChakraThemeProvider from './common/styles/chakraTheme';
import { OuraThemeProvider } from './common/styles/theme';
import { getFeatureFlagProvider } from './components/FeatureFlags';
import LoadingScreen from './components/LoadingScreen';
import ErrorFallback from './components/shared/ErrorBoundary/ErrorFallBack';
import { LocaleProvider } from './localization/LocaleContext';

const AuthenticatedApp = lazy(
  () =>
    import(
      /* webpackChunkName: "AuthenticatedApp" */ './components/AuthenticatedApp'
    ),
);

const UnAuthenticatedApp = lazy(
  () =>
    import(
      /* webpackChunkName: "UnAuthenticatedApp" */ './components/UnAuthenticatedApp'
    ),
);

const DeepLinkApp = lazy(
  () =>
    import(
      /* webpackChunkName: "DeepLinkApp" */ './components/unauthenticated/DeepLinkApp'
    ),
);

const rootEl = document.getElementById('root');

ReactModal.setAppElement(rootEl!);

// eslint-disable-next-line unicorn/prefer-top-level-await
(async () => {
  const FeatureFlagProvider = await getFeatureFlagProvider();

  if (rootEl) {
    render(
      <FeatureFlagProvider>
        <ChakraThemeProvider>
          <OuraThemeProvider>
            <LocaleProvider>
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logException}
              >
                <AppProvider>
                  <Suspense fallback={<LoadingScreen />}>
                    <Routes>
                      <Route path="/user/*" element={<UnAuthenticatedApp />} />
                      <Route path="/app/*" element={<DeepLinkApp />} />
                      <Route path="*" element={<AuthenticatedApp />} />
                    </Routes>
                  </Suspense>
                </AppProvider>
              </ErrorBoundary>
            </LocaleProvider>
          </OuraThemeProvider>
        </ChakraThemeProvider>
      </FeatureFlagProvider>,
      rootEl,
    );
  }
})();
